/**
 * FULLSTORY ORG
 */
export const FULLSTORY_ORG_ID = 'SFQQ7';

/**
 * HOST
 */

let HOST = 'https://transfer.pilot.zapier.com';
let clientID = `ygAtHSAERQ3HsfyDvYCndI79iCA6kh77ptGJvsVp`
console.log(process.env.REACT_APP_REMOTE)
if (process.env.NODE_ENV === 'development') {
  HOST = 'http://localhost:4001' ;
  clientID = `yPYHZWloqyrIBQDExqerAHx7DRDS3nkrNijEMp6f`
}

export const REDIRECT_URI = `${HOST}/oauth/complete/`;

/**
 * REMOTE LOGIN (OAUTH) URLS
 */

// Prod
export const ZAPIER_OAUTH_URL =
  'https://zapier.com/oauth/authorize/?' +
  `client_id=${clientID}&` +
  `redirect_uri=${REDIRECT_URI}&` +
  'response_type=code&' +
  'scope=internal+profile';

/**
 * Colors
 */

export const Colors = {
  Blue: '#146af5',
  Green: '#13d1ab',
  Orange: '#ff4a00',
  InputGray: '#f0f4f5',
  White: 'white',
};

export const CURRENT_PATH_KEY = 'CURRENT_PATH_KEY';
